import api from '@api'
import {
  transformProjectForList,
  transformProjectForServer,
  transformProject,
  transformMember,
} from '@data/project'
import {
  getRootTranslator,
  getRootPluaralTranslator,
  // getModuleTranslator,
} from '@/src/utils/get-module-translator'
import { successToast } from '@motadata/ui'

// import config from './config'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()
// const __moduleTc = getModuleTranslator(config.translationKey)
export function searchProjectsApi(qualifications, limit, offset, sortedColumn) {
  return api
    .post(
      `/project/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformProjectForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function createApi(project) {
  return api.post('/project', transformProjectForServer(project), {
    message: __rootT('created_successfully', {
      resource: __rootTc('project', 1),
    }),
  })
}

export function cloneProjectApi(project, id) {
  return api.post(`/project/clone/${id}`, transformProjectForServer(project), {
    message: __rootT('created_successfully', {
      resource: __rootTc('project', 1),
    }),
  })
}

export function getApi(id, isPublic) {
  return api
    .get(`${isPublic ? '/cportal' : ''}/project/${id}`)
    .then(transformProject)
}

export function exportDataApi(data) {
  return api.post(
    '/analytics/export/griddata',
    data,
    {
      message: __rootT('export_successfully', {
        resource: __rootTc(data.model),
      }),
    },
    {
      notification: {
        error: {
          message: __rootT('error'),
        },
      },
    }
  )
}
export function deleteApi(id) {
  return api.delete(`/project/${id}`, {
    message: __rootT('archived_successfully', {
      resource: __rootTc('project', id.length),
    }),
  })
}

export function getMembersApi(id, pageSize, offset) {
  return api
    .get(`project/memberdata/${id}`, {
      params: {
        offset: offset || 0,
        size: pageSize || 1000,
      },
      notify: false,
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformMember),
        total: data.totalCount,
      }
    })
}

export function bulkUnarchiveApi(ids) {
  return api.patch(
    `/project/bulk/unarchive`,
    { objectIds: ids },
    {
      message: __rootT('updated_successfully', {
        resource: __rootTc('project', ids.length),
      }),
    }
  )
}

export function bulkPermanentDeleteApi(ids) {
  return api.delete(`/project/bulk/permanentdelete`, {
    data: { objectIds: ids },
    message: __rootT('deleted_successfully', {
      resource: __rootTc('project', ids.length),
    }),
  })
}

export function bulkDeleteApi(ids) {
  return api
    .delete(`/project/bulk/delete`, {
      data: { objectIds: ids },
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          __rootT('archived_successfully', {
            resource: __rootTc('project', ids.length),
          })
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function updateApi({ id, ...change }) {
  return api
    .patch(`/project/${id}`, transformProjectForServer(change), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('project', 1),
      }),
    })
    .then(transformProject)
}

export function bulkUpdateApi(id, project, type, action) {
  const payload = {
    objectIds: id,
    updateMap: transformProjectForServer(project),
  }
  return api
    .patch(`/project/bulk/update`, payload, {
      notify: true,
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          `${__rootT(
            data.successIds.length > 1
              ? action === 'add'
                ? 'added_successfully_multiple'
                : 'updated_successfully_multiple'
              : action === 'add'
              ? 'added_successfully'
              : 'updated_successfully',
            {
              resource: __rootTc(type || 'project', data.successIds.length),
            }
          )}`
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}
