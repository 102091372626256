<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { DepartmentMethods } from '@state/modules/department'
import { LocationMethods } from '@state/modules/location'
import { PriorityActions } from '@state/modules/priority'
import { ImpactMethods } from '@state/modules/impact'
import { UrgencyMethods } from '@state/modules/urgency'
import { SourceMethods } from '@state/modules/source'
import { TaskMethods } from '@state/modules/task'
import { TechnicianMethods } from '@state/modules/technician'
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'OrganizationModule',
  page() {
    return {
      title: this.$t('organization'),
    }
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === 'admin.organization.department' && this.fetchDepartment) {
      this.fetchDepartment()
    }
    if (from.name === 'admin.organization.location' && this.fetchLocation) {
      this.fetchLocation()
    }
    if (from.name === 'admin.organization.priority' && this.fetchPriority) {
      this.fetchPriority()
    }
    if (from.name === 'admin.organization.impact' && this.fetchImpact) {
      this.fetchImpact()
    }
    if (from.name === 'admin.organization.urgency' && this.fetchUrgency) {
      this.fetchUrgency()
    }
    if (from.name === 'admin.organization.source' && this.fetchSource) {
      this.fetchSource()
    }
    if (from.name === 'admin.organization.task-type' && this.fetchTaskType) {
      this.fetchTaskType()
    }
    if (
      from.name === 'admin.organization.leave-management' &&
      this.fetchTaskType
    ) {
      this.fetchTechnicians()
    }
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
  },
  created() {
    if (this.myAllowedModules.indexOf('admin.organization') === -1) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
  methods: {
    ...DepartmentMethods,
    ...LocationMethods,
    ...PriorityActions,
    ...ImpactMethods,
    ...UrgencyMethods,
    ...SourceMethods,
    ...TaskMethods,
    ...TechnicianMethods,
  },
}
</script>
