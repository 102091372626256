<template>
  <FlotoFixedView>
    <FlotoPageHeader
      :back-link="backLink || $currentModule.getRoute('request')"
      class="px-4"
      use-divider
      :title="service.name || `${$tc('request')} ${$tc('service')}`"
    >
      <template v-slot:back-button>
        <slot name="back-button"></slot>
      </template>
    </FlotoPageHeader>
    <div class="min-h-0 mt-3">
      <FlotoContentLoader :loading="loading" class="h-100">
        <FormRulesProvider
          :key="service.id"
          :module-name="$constants.SERVICE_CATALOG"
          :parent-resource-id="service.id"
          :custom-fields="serviceFormFields"
        >
          <MRow class="w-full min-h-0">
            <MCol auto-size>
              <div class="pl-6 pr-4">
                <img :src="service.image" width="150" height="150" />
              </div>
            </MCol>
            <MCol class="flex flex-col h-100 flex-1">
              <FlotoScrollView>
                <h4 class="text-primary font-semibold">{{ service.name }}</h4>
                <div
                  v-if="
                    (!isPortalLogin && service.amount > 0) ||
                    (isPortalLogin && service.showInCustomerPortalAmount)
                  "
                  class="text-neutral flex"
                >
                  {{ $tc('cost') }}: {{ service.amount }}
                  <CurrencyPicker
                    v-model="service.currencyId"
                    text-only
                    disabled
                  />
                </div>
                <div class="flex flex-col">
                  {{ service.serviceDescription }}
                </div>
                <template v-if="serviceFormFields.length">
                  <MDivider />
                  <h5 class="text-primary">{{ $t('additional_info') }}</h5>
                </template>
                <FormConsumer
                  v-if="serviceFormFields.length"
                  :key="serviceFormFields.length"
                  ref="formRef"
                  v-model="request"
                  :with-submit="withSubmit"
                  :form-fields="serviceFormFields"
                  :module-name="$constants.SERVICE_CATALOG"
                  :external-data="{
                    requestType: 'service_request',
                    serviceCatalogId: $route.params.id,
                  }"
                  apply-form-rules
                  @change="$emit('change', $event)"
                  @submit="handleFormSubmitted"
                >
                  <template v-slot:submit>
                    <span />
                  </template>
                  <template v-slot:reset>
                    <span />
                  </template>
                </FormConsumer>
                <LinkAssetDrawer
                  v-if="service.allowRequesterToLinkMyAsset"
                  :module-name="$constants.ASSET"
                  :default-selected-assets="selectedAssets"
                  :service-catalog-id="$route.params.id"
                  class="ml-2"
                  @change="handleLinkAssetSelectionChange"
                />
                <MDivider v-if="withSubmit" />
                <div v-if="withSubmit" class="flex justify-end mb-4">
                  <MButton
                    id="submit-request"
                    :loading="processing"
                    class="mx-1"
                    @click="submitForm"
                  >
                    {{ $t('submit_request') }}
                  </MButton>
                  <FlotoLink
                    class="ml-4"
                    :to="backLink || $currentModule.getRoute('request')"
                    variant="default"
                    as-button
                  >
                    {{ $t('cancel') }}
                  </FlotoLink>
                </div>
              </FlotoScrollView>
            </MCol>
          </MRow>
        </FormRulesProvider>
      </FlotoContentLoader>
    </div>
  </FlotoFixedView>
</template>

<script>
import CurrencyPicker from '@components/data-picker/currency-picker'
import { authComputed } from '@state/modules/auth'
import { OrganizationComputed } from '@state/modules/organization'
import { PreferenceComputed } from '@state/modules/preference'
import { getFormApi } from '@modules/form/form-api'
import FormConsumer from '@components/form-consumer.vue'
// import { getFormRulesApi } from '@modules/form-rules/form-rules-api'
import { getFieldByRequesterAccess } from '@modules/support-portal/helpers/field-access'
import {
  getServiceCatalogApi,
  createServiceRequestApi,
} from '../service-catalog-api'
import { SupportPortalConfigComputed } from '@state/modules/support-portal-config'
import FormRulesProvider from '@components/providers/form-rules-provider/form-rules-provider'
import LinkAssetDrawer from '@modules/ticket/components/link-asset-drawer'

export default {
  name: 'RequestService',
  components: {
    FormConsumer,
    FormRulesProvider,
    CurrencyPicker,
    LinkAssetDrawer,
  },
  model: { event: 'change' },
  props: {
    backLink: { type: Object, default: undefined },
    createdLink: { type: Function, default: undefined },
    serviceId: { type: Number, default: undefined },
    // eslint-disable-next-line
    withSubmit: { type: Boolean, default: true },
  },
  data() {
    return {
      service: {},
      form: {},
      processing: false,
      loading: true,
      request: {},
      formRules: [],
      selectedAssets: [],
    }
  },
  computed: {
    ...authComputed,
    ...OrganizationComputed,
    ...PreferenceComputed,
    ...SupportPortalConfigComputed,
    serviceFormFields() {
      const enablePriorityMatrix =
        this.tenantPrefrences.EnablePriorityMatrix &&
        this.tenantPrefrences.EnablePriorityMatrix.value
      let serviceCatalogData = {
        id: 5,
        type: 'SystemFieldRest',
        isSystemField: true,
        // refactor requester_email to requester
        paramName: 'requesterId',
        inputType: 'requesterid',
        guid: 'ef44f47a-7f88-409c-a7a0-f18d53ccf6cb',
        title: this.$tc('requester'),
        attributes: {
          widthClass: 'w-full',
          inputWidth: 40,
          disabled:
            this.isPortalLogin &&
            !this.allowRequesterToCreateRequestBehalfOfOtherRequester,
        },
        ...(this.isPortalLogin ? { defaultValue: (this.user || {}).id } : {}),
        order: 2,
        required: true,
        useOnPortal: true,
        requesterCanEdit: this.isPortalLogin
          ? this.allowRequesterToCreateRequestBehalfOfOtherRequester
          : true,
        requesterRequired: true,
        sectionId: 0,
        canDelete: false,
        canUpdate: true,
        stage: '__vue_devtool_undefined__',
        percentageField: '__vue_devtool_undefined__',
        hidden: false,
      }
      serviceCatalogData = [serviceCatalogData, ...(this.form.fields || [])]
      if (enablePriorityMatrix) {
        serviceCatalogData = serviceCatalogData.filter(
          (s) => s.inputType !== 'priority'
        )
      }
      if (this.isPortalLogin) {
        // return (this.form.fields || []).filter((f) => f.useOnPortal)
        return getFieldByRequesterAccess(
          serviceCatalogData.filter((f) => f.useOnPortal),
          this.user
        )
      }

      // serviceCatalogData = this.form.fields || []
      return serviceCatalogData
    },
  },
  created() {
    getServiceCatalogApi(
      this.serviceId || this.$route.params.id,
      undefined,
      this.isPortalLogin
    ).then(
      (data) => {
        this.service = data
        this.loading = false
        this.getForm()
      },
      () => this.$router.replace({ name: '404' })
    )
    // this.getFormRules()
  },
  methods: {
    getForm() {
      getFormApi(
        this.$constants.SERVICE_CATALOG,
        this.service.id,
        undefined,
        this.isPortalLogin
      ).then((data) => {
        this.form = data
      })
    },
    // getFormRules() {
    //   return getFormRulesApi({
    //     moduleName: this.$constants.SERVICE_CATALOG,
    //     parentId: this.serviceId || this.$route.params.id,
    //     name: '',
    //   }).then((data) => {
    //     this.formRules = data.items.filter((i) => i.enabled)
    //   })
    // },
    submitForm() {
      if (this.serviceFormFields.length) {
        return this.$refs.formRef.submit()
      }
      return this.handleFormSubmitted({})
    },
    handleFormSubmitted(request) {
      this.processing = true
      createServiceRequestApi(
        this.service.id,
        {
          ...request,
          linkAssetIds: this.selectedAssets.map((a) => `${a.id}:${a.model}`),
        },
        this.isPortalLogin
      )
        .then((data) => {
          this.$router.push(
            this.createdLink
              ? this.createdLink(data.id)
              : this.$modules.getModuleRoute('ticket', 'view', {
                  params: { id: data.id, ticketType: this.$constants.REQUEST },
                })
          )
        })
        .finally(() => (this.processing = false))
    },
    handleLinkAssetSelectionChange(items) {
      this.selectedAssets = items
      this.$emit('change', {
        ...this.value,
        linkAssetIds: items.map((a) => `${a.id}:${a.model}`),
      })
    },
  },
}
</script>
