import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  transformSsoConfig,
  transformSsoConfigForServer,
} from '@data/sso-config'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

// product-type
const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        qualificationFactors.name,
        'string',
        'db'
      )
    )
  }
  if ('ids' in qualificationFactors && qualificationFactors.ids.length) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualificationFactors.ids,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function searchSsoConfigApi(
  filter,
  limit,
  offset,
  additionalUrlParams = {}
) {
  return api
    .post(
      `/ssoConfig/search/byqual`,
      {
        qualDetails:
          filter.name || filter.ids ? buildQualifications(filter) : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionalUrlParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformSsoConfig)
          : [],
        total: data.totalCount,
      }
    })
}

export function createSsoConfigApi(data) {
  return api
    .post(`/ssoConfig`, transformSsoConfigForServer(data), {
      message: __rootT('added_successfully', {
        resource: __rootTc('identity_provider'),
      }),
    })
    .then(transformSsoConfig)
}

export function updateSsoConfigApi(data) {
  return api
    .patch(`/ssoConfig/${data.id}`, transformSsoConfigForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('identity_provider'),
      }),
    })
    .then(transformSsoConfig)
}

export function deleteSsoConfigApi(id) {
  return api.delete(`/ssoConfig/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('identity_provider'),
    }),
  })
}

export function getSsoPreferenceApi(data) {
  return api.get(`/ssopreference`)
}

export function updateSsoPreferenceApi(data) {
  return api.patch(`/ssopreference`, data, {
    message: __rootT('updated_successfully', {
      resource: __rootTc('sso_preference'),
    }),
  })
}
