<template>
  <MRow class="px-4">
    <MCol>
      <template v-if="type === 'summary_task'">
        <FlotoFormItem
          id="subject-input"
          v-model="task.name"
          rules="required|min:1 |max:100"
          auto-focus
          :label="$t('subject')"
          :placeholder="$t('subject')"
        />
        <template v-if="task.id">
          <MRow>
            <MCol :sm="6" :size="6">
              <FlotoFormItem
                id="duration-input"
                rules="max_value|min_value:0"
                :label="`${$tc('duration')} (${$tc('days', 2)})`"
              >
                <MInputNumber
                  id="duration-days-input"
                  v-model="task.duration"
                  disabled
                  type="number"
                  :precision="0"
                />
              </FlotoFormItem>
            </MCol>
          </MRow>
          <MRow>
            <MCol :sm="6" :size="6">
              <FlotoFormItem
                id="start-date-picker"
                :label="`${$t('start')} ${$t('date')}`"
              >
                <FlotoDatePicker
                  v-model="(task.timeRange || {}).startTime"
                  disabled
                />
              </FlotoFormItem>
            </MCol>
            <MCol :sm="6" :size="6">
              <FlotoFormItem
                id="end-date-picker"
                :label="`${$t('end')} ${$t('date')}`"
              >
                <FlotoDatePicker
                  v-model="(task.timeRange || {}).endTime"
                  d
                  disabled
                />
              </FlotoFormItem>
            </MCol>
          </MRow>
          <MRow>
            <MCol :sm="12" :size="12">
              <FlotoFormItem
                id="completion-picker"
                rules="max_value:100|min_value:0"
                :label="`${$tc('completion')}`"
              >
                <SliderPicker
                  v-model="task.completion"
                  disabled
                  :min="0"
                  :max="100"
                />
              </FlotoFormItem>
            </MCol>
          </MRow>
        </template>
      </template>
      <template v-else>
        <FlotoFormItem
          id="subject-input-2"
          v-model="task.subject"
          rules="required|subject"
          auto-focus
          :label="$t('subject')"
          :placeholder="$t('subject')"
        />
        <MRow>
          <MCol :sm="6" :size="12">
            <FlotoFormItem
              id="technician-group-picker"
              :label="$tc('technician_group')"
              :placeholder="$tc('technician_group')"
            >
              <FlotoTechnicianGroupPicker v-model="task.groupId" as-input />
            </FlotoFormItem>
          </MCol>
          <MCol :sm="6" :size="12">
            <FlotoFormItem id="task-type-picker" :label="`${$tc('task_type')}`">
              <FlotoTaskTypePicker
                v-model="task.taskTypeId"
                :excluded-types="type === 'task' ? ['milestone'] : []"
                :disabled="type === 'milestone'"
                as-input
              />
            </FlotoFormItem>
          </MCol>
        </MRow>
        <MRow>
          <MCol :sm="6" :size="12">
            <FlotoFormItem id="assigned-to-picker" :label="$t('assigned_to')">
              <FlotoTechnicianPicker
                v-model="task.technicianId"
                as-input
                mandatory-selection
                :group-id="task.groupId"
              />
            </FlotoFormItem>
          </MCol>
        </MRow>
        <MRow>
          <MCol :sm="6" :size="6">
            <FlotoFormItem
              id="duration-days-input"
              v-model="task.duration"
              :placeholder="`${$tc('duration')} (${$tc('days', 2)})`"
              rules="duration_days|max_value|min_value:0"
              :label="`${$tc('duration')} (${$tc('days', 2)})`"
            >
            </FlotoFormItem>
          </MCol>
          <MCol :sm="6" :size="6">
            <FlotoFormItem
              id="start-date-picker-2"
              :label="`${$t('start')} ${$t('date')}`"
              rules="required"
            >
              <FlotoDatePicker
                v-model="(task.timeRange || {}).startTime"
                :disabled="fromMyTask"
              />
            </FlotoFormItem>
          </MCol>
        </MRow>
        <MRow>
          <MCol :sm="12" :size="12">
            <FlotoFormItem
              id="completion-picker-2"
              rules="max_value:100|min_value:0"
              :label="`${$tc('completion')}`"
            >
              <SliderPicker v-model="task.completion" :min="0" :max="100" />
            </FlotoFormItem>
          </MCol>
        </MRow>
        <MRow>
          <MCol :sm="6" :size="12">
            <FlotoFormItem id="status-picker" :label="$t('status')">
              <FlotoStatusPicker
                v-model="task.statusId"
                mandatory
                :allow-clear="false"
                as-input
                :allowed-status="type === 'milestone' ? ['open', 'closed'] : []"
                :module-name="$constants.TASK"
              />
            </FlotoFormItem>
          </MCol>
          <MCol :sm="6" :size="12">
            <FlotoFormItem id="priority-picker" :label="$t('priority')">
              <FlotoPriorityPicker
                v-model="task.priorityId"
                :allow-clear="false"
                as-input
              />
            </FlotoFormItem>
          </MCol>
          <MCol :sm="6" :size="12">
            <FlotoFormItem
              id="notify-before-picker"
              :label="$t('notify_before')"
              :rules="notifyBeforeHoursValidations"
              :validation-label="`${$tc('notify_before')} ${$tc(
                task.notifyBeforeHoursTimeUnit,
                2
              )}`"
            >
              <FlotoEstimatedTimePicker
                v-model="task.notifyBeforeHours"
                :allowed-units="['hours', 'minutes', 'days']"
                :time-unit.sync="task.notifyBeforeHoursTimeUnit"
                as-input
              />
            </FlotoFormItem>
          </MCol>
        </MRow>
        <FlotoFormItem
          id="description-input"
          type="textarea"
          :rows="3"
          :label="$t('description')"
        >
          <FlotoRichEditor
            v-model="task.description"
            :placeholder="$t('description')"
          />
        </FlotoFormItem>
        <FlotoAttachment id="attachment-link" v-model="task.fileAttachemnts" />
      </template>
    </MCol>
  </MRow>
</template>

<script>
import SliderPicker from '@components/numeric-slider/slider-picker'
import { getValidationRulesForEstimatedTime } from '@components/task/utils.js'
export default {
  name: 'TaskForm',
  components: { SliderPicker },
  props: {
    task: { type: Object, required: true },
    fromMyTask: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: 'task' },
  },
  computed: {
    notifyBeforeHoursValidations() {
      const validations = {
        // nonzero: true,
        ...getValidationRulesForEstimatedTime(
          this.task.notifyBeforeHoursTimeUnit
        ),
      }
      return validations
    },
  },
}
</script>
