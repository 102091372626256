var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-1 items-center min-w-0",class:{ 'cursor-pointer': _vm.$listeners.click },on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"flex flex-1 justify-between"},[_c('div',{staticClass:"flex flex-1 items-center"},[(_vm.item.type !== 'summary_task' && !_vm.isMilestone)?_c('ColorPicker',{staticClass:"mx-1",attrs:{"disabled":_vm.disabled || _vm.isMilestone,"value":_vm.item.color},on:{"change":function($event){return _vm.$emit('update', {
            type: _vm.item.type,
            taskId: _vm.item.taskId,
            summaryTaskId: _vm.item.summaryTaskId,
            color: $event,
          })}}}):_vm._e(),(_vm.isMilestone)?_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mx-1",staticStyle:{"color":"#ffbf00"},attrs:{"name":"diamond","size":"2x"}})]},proxy:true}],null,false,2658446678)},[_vm._v(" "+_vm._s(_vm.$tc('milestone'))+" ")]):_vm._e(),(_vm.item.type !== 'summary_task')?_c('FlotoUserAvatar',{staticClass:"mx-1 mt-1",staticStyle:{"min-width":"35px"},attrs:{"show-tooltip":"","size":35,"user-id":_vm.item.technicianId,"default-text":_vm.$tc('unassigned')}}):_vm._e(),_c('div',{staticClass:"flex flex-1 flex-col"},[_c('div',{staticClass:"flex items-center mb-1 text-black"},[(_vm.item.type === 'summary_task')?[_c('b',{staticClass:"mr-2"},[_vm._v("Summary Task: ")]),_vm._v(" "+_vm._s(_vm.item.name)+" ")]:[_c('FlotoTaskDrawer',{attrs:{"disabled":_vm.disabled,"default-task":Object.assign({}, _vm.item, {id: _vm.item.taskId})},on:{"update":function ($event) { return _vm.handleTaskDetailUpdate($event, _vm.item); }},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
          var open = ref.open;
return [_c('a',{on:{"click":open}},[_vm._v(" "+_vm._s(_vm.item.name)+": "+_vm._s(_vm.item.subject)+" ")])]}}])})]],2),_c('div',{staticClass:"pl-1"},[_c('small',{staticClass:"text-neutral"},[_c('MTooltip',{staticClass:"mr-2",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mr-1 text-neutral-light md-text-base",attrs:{"name":"calendar-alt"}}),(
                    (_vm.item.timeRange || {}).startTime ||
                    (_vm.item.timeRange || {}).endTime
                  )?[_c('span',[_vm._v(_vm._s(_vm._f("datetime")((_vm.item.timeRange || {}).startTime,'DD/MM/YYYY hh:mm A')))]),(
                      (_vm.item.timeRange || {}).startTime &&
                      (_vm.item.timeRange || {}).endTime
                    )?_c('span',{staticClass:"text-center my-1"},[_vm._v(" - ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("datetime")(_vm.item.timeRange.endTime,'DD/MM/YYYY hh:mm A')))])]:[_vm._v(" --- ")]]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('start'))+" "+_vm._s(_vm.$t('date'))+" - "+_vm._s(_vm.$t('end'))+" "+_vm._s(_vm.$t('date'))+" ")]),_c('MTooltip',{staticClass:"mr-2",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mr-1 text-neutral-light md-text-base",attrs:{"name":"clock"}}),(_vm.item.duration)?[_c('span',[_vm._v(" "+_vm._s(_vm.item.duration)+" "+_vm._s(_vm.$tc('days', _vm.item.duration))+" ")])]:[_vm._v(" --- ")]]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('duration'))+" ("+_vm._s(_vm.$tc('days', 2))+") ")]),(_vm.item.type !== 'summary_task')?_c('MTooltip',{staticClass:"mr-2",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mr-1 text-neutral-light md-text-base",attrs:{"name":"hourglass"}}),(_vm.item.estimatedTime)?[_c('span',[_vm._v(" "+_vm._s(_vm.item.estimatedTime)+" "+_vm._s(_vm.item.estimatedTimeUnit)+" ")])]:[_vm._v(" --- ")]]},proxy:true}],null,false,3582632455)},[_vm._v(" "+_vm._s(_vm.$t('estimates'))+" ")]):_vm._e(),(
                _vm.item.type !== 'summary_task' &&
                (_vm.overDue !== false || _vm.dueIn !== false)
              )?_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mr-2",class:{
                    'text-secondary-red': _vm.overDue,
                    'text-secondary-green': !_vm.overDue,
                  },attrs:{"size":"lg","name":"clock"}}),_c('span',{staticClass:"text-secondary-red"},[_vm._v(_vm._s(_vm.overDue ? _vm.$tc('overdue') : ''))])]},proxy:true}],null,false,2353468999)},[_vm._v(" "+_vm._s(_vm.overDue || _vm.dueIn)+" "),_c('br'),_vm._v(" ("+_vm._s(_vm._f("datetime")(_vm.item.timeRange.endTime))+") ")]):_vm._e()],1)])])],1),_c('div',{staticClass:"actions flex items-center"},[_c('div',{staticClass:"flex-1 items-center"},[(_vm.item.predecessors.length || _vm.item.successors.length)?_c('DependencyViewDrawer',{staticClass:"mr-1",attrs:{"task":_vm.item,"tasks":_vm.tasks,"resource-id":_vm.resourceId},on:{"change":_vm.handleDependencyGraphUpdate}}):_vm._e(),(!_vm.disabled)?_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mr-2 cursor-pointer text-neutral-light",attrs:{"name":"pencil"},on:{"click":function($event){return _vm.$emit('edit', _vm.item)}}})]},proxy:true}],null,false,3031724637)},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]):_vm._e(),(!_vm.disabled)?_c('FlotoDeleteBtn',{attrs:{"message":_vm.item.type === 'summary_task'
              ? _vm.$t('confirm_remove_summary_task')
              : _vm.$t('confirm_remove_item', {
                  item: ("" + (_vm.$tc(_vm.isMilestone ? 'milestone' : _vm.item.type))),
                })},on:{"confirm":function($event){return _vm.$emit('remove', _vm.item)}}},[_c('MIcon',{attrs:{"name":"trash-alt"}})],1):_vm._e()],1),(!_vm.disabled)?_c('MDropdown',{ref:"dropdownMenu",staticClass:"ml-3",scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MButton',{staticClass:"text-neutral mr-3",staticStyle:{"width":"10px"},attrs:{"variant":"transparent","shadow":false,"shape":"circle"}},[_c('MIcon',{attrs:{"name":"ellipsis-v","size":"2x"}})],1)]},proxy:true},{key:"menu",fn:function(){return [_c('MMenu',{staticClass:"action-menu"},[(!_vm.disabled && _vm.item.type === 'summary_task')?_c('MMenuItem',[_c('a',{on:{"click":function () {
                    _vm.$emit('add-action', 'task', _vm.item)
                    _vm.hide()
                  }}},[_c('MIcon',{staticClass:"action-menu-icon",attrs:{"name":"tasks"}}),_c('span',{staticClass:"ml-1 action-menu-text"},[_vm._v(" "+_vm._s(_vm.$t('add'))+" "+_vm._s(_vm.$tc('task'))+" ")])],1)]):_vm._e(),(!_vm.disabled && _vm.item.type === 'summary_task')?_c('MMenuItem',[_c('a',{on:{"click":function () {
                    _vm.$emit('add-action', 'milestone', _vm.item)
                    _vm.hide()
                  }}},[_c('MIcon',{staticClass:"action-menu-icon",attrs:{"name":"diamond"}}),_c('span',{staticClass:"ml-1 action-menu-text"},[_vm._v(" "+_vm._s(_vm.$t('add'))+" "+_vm._s(_vm.$tc('milestone'))+" ")])],1)]):_vm._e(),(!_vm.disabled && _vm.item.type !== 'summary_task')?_c('MMenuItem',[_c('a',{on:{"click":function () {
                    _vm.handleAddDependency('predecessors')
                  }}},[_c('MIcon',{staticClass:"action-menu-icon",attrs:{"name":"stream"}}),_c('span',{staticClass:"ml-1 action-menu-text"},[_vm._v(" "+_vm._s(_vm.$t('add'))+" "+_vm._s(_vm.$tc('predecessors'))+" ")])],1)]):_vm._e(),(!_vm.disabled && _vm.item.type !== 'summary_task')?_c('MMenuItem',[_c('a',{on:{"click":function () {
                    _vm.handleAddDependency('successors')
                  }}},[_c('MIcon',{staticClass:"action-menu-icon",attrs:{"name":"stream"}}),_c('span',{staticClass:"ml-1 action-menu-text"},[_vm._v(" "+_vm._s(_vm.$t('add'))+" "+_vm._s(_vm.$tc('successors'))+" ")])],1)]):_vm._e()],1)]},proxy:true}],null,false,3398061989)}):_vm._e(),_c('div',{staticClass:"flex items-center"},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"task-item p-1 rounded-full"},[_c('ProgressViewer',{attrs:{"value":_vm.item.completion}})],1)]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$tc('completion'))+" ")])],1)],1)]),_c('FlotoDrawer',{attrs:{"open":Boolean(_vm.dependency)},on:{"hide":_vm.hideDependencyDrawer},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$tc('add'))+" "+_vm._s(_vm.$tc('dependency'))+" - "+_vm._s(_vm.$tc(_vm.dependency))+" ")]},proxy:true},{key:"actions",fn:function(ref){
                  var hideDrawer = ref.hide;
return [_c('MButton',{staticClass:"mr-2",attrs:{"id":"add-btn","outline":"","loading":_vm.processing},on:{"click":_vm.handleSubmitDependency}},[_vm._v(" "+_vm._s(_vm.$t('add'))+" ")]),_c('MButton',{attrs:{"id":"cancel-btn","variant":"default"},on:{"click":hideDrawer}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]}}])},[_c('DependencySelectionList',{attrs:{"tasks":_vm.availableTaskList},model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }