import api from '@api'
import { successToast, errorToast } from '@motadata/ui'
import {
  transformPatchForList,
  transformPatchForDetail,
  transformPatchForServer,
  transformOsApplicationForList,
  transformPatchCommandForList,
} from '@data/patch'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
  buildNameFilterQuery,
} from '@data/qualification'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@/src/utils/get-module-translator'
const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const patchTypeQual = (
  paramName,
  operator = 'equal',
  value = 0,
  valueType = 'long',
  paramType = 'variable'
) =>
  buildRelationalQualificationStructure(
    paramName,
    operator,
    value,
    valueType,
    paramType
  )

const getQualifications = (filters) => {
  const qualDetails = []
  if (filters.name) {
    qualDetails.push(buildNameFilterQuery(filters.name))
  }
  if (filters.selectedName && filters.selectedName.length) {
    qualDetails.push(buildNameFilterQuery(filters.selectedName, 'in'))
  }
  if ('ids' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  if ('platform' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'platform',
        'in',
        [filters.platform],
        'enum',
        'db'
      )
    )
  }
  if ('productType' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'productType',
        'in',
        ['os'],
        'enum',
        'db'
      )
    )
  }
  if ('excludedIds' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        filters.excludedIds,
        'long',
        'db'
      )
    )
  }
  if ('patchType' in filters) {
    qualDetails.push(patchTypeQual(filters.patchType))
    if (filters.patchType === 'installed_patches') {
      qualDetails.push(
        buildRelationalQualificationStructure(
          'patchUnInstallationSupported',
          'equal',
          true,
          'boolean',
          'db'
        )
      )
    }
    if (
      filters.patchType === 'missing_patches' ||
      filters.patchType === 'installed_patches'
    ) {
      qualDetails.push(
        buildRelationalQualificationStructure(
          'patchApprovalStatus',
          'not_in',
          ['declined'],
          'enum',
          'db'
        )
      )
    }
    if (filters.patchType === 'installed_patches') {
      qualDetails.push(
        buildRelationalQualificationStructure(
          'platform',
          'not_in',
          ['mac'],
          'enum',
          'db'
        )
      )
    }
  }
  return qualDetails
}

export function searchPatchApi(
  filters,
  searchCriterias,
  limit,
  offset,
  sortedColumn
) {
  const qualDetails = [
    ...((searchCriterias || {}).quals || []),
    ...getQualifications(filters),
  ]
  return api
    .post(
      `/patch/search/byqual`,
      {
        qualDetails:
          qualDetails.length && Object.keys(filters || {}).length > 0
            ? buildFlatQualificationStructure(qualDetails)
            : searchCriterias !== null
            ? searchCriterias
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformPatchForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function manualpatchcreateApi(data) {
  return api
    .post('/manualpatchcreate', transformPatchForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('patch'),
      }),
    })
    .then(transformPatchForList)
}
export function manualpatchDeleteApi(id, data) {
  return api
    .post(
      `/manualpatchcreate/${id}/delete`,
      // transformPatchForServer(data),
      { agentIds: data },
      {
        message: __rootT('deleted_successfully', {
          resource: __rootTc('computer'),
        }),
      }
    )
    .then(transformPatchForList)
}
export function uploadPatchFileDetailsApi(id, data) {
  return api
    .post(
      `/manualpatchcreate/${id}/uploadfile`,
      { files: data },
      {
        message: __rootT('file_uploaded', {
          fileName: __rootTc('patch'),
        }),
      }
    )
    .then(transformPatchForList)
}
export function createComputerApi(id, data) {
  return api
    .post(`/manualpatchcreate/${id}/missing`, transformPatchForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('patch', 1),
      }),
    })
    .then(transformPatchForDetail)
}

export function getApi(id) {
  return api.get(`/patch/${id}`).then(transformPatchForDetail)
}

export function updateApi({ id, ...change }, type, action) {
  return api
    .patch(`/patch/${id}`, transformPatchForServer(change), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('patch', 1),
      }),
    })
    .then(transformPatchForDetail)
}

export function searchOsApplicationApi(
  filters,
  searchCriterias,
  limit,
  offset,
  sortedColumn,
  additionalParams = {}
) {
  const qualDetails = [
    ...((searchCriterias || {}).quals || []),
    ...getQualifications(filters),
  ]
  return api
    .post(
      `/osapplication/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
          ...additionalParams,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformOsApplicationForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function searchLinuxOsApplicationApi(
  filters,
  searchCriterias,
  limit,
  offset,
  linuxModule
) {
  const qualDetails = [
    ...((searchCriterias || {}).quals || []),
    ...getQualifications(filters),
  ]
  return api
    .post(
      `${linuxModule}/osapplication/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformOsApplicationForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function searchInstallationCommnadApi(
  refId,
  type,
  limit,
  offset,
  additionaParams = {}
) {
  return api
    .post(
      `/${type}/${refId}/installations`,
      {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionaParams,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformPatchCommandForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function searchPatchBasedOnComputerApi(
  computerId,
  patchType,
  filters,
  searchCriterias,
  limit,
  offset,
  sortedColumn
) {
  const qualDetails = [
    ...((searchCriterias || {}).quals || []),
    ...getQualifications(filters),
  ]
  return api
    .post(
      `/patch/${patchType}/computer/${computerId}/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformPatchForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function getLanguageMap() {
  return api.get('/patchlanguage/all')
}

export function downloadPatchApi(modelName, data) {
  return api.post(
    `/configurationdownloadstatus/manualdownload/${modelName}`,
    data
  )
}

export function downloadInstallationApi(id, resourceId) {
  return api
    .post(
      `configurationdownloadstatus/${id}/${resourceId}/faileddownload/retry`
    )
    .then((data) => {
      if (data === true) {
        successToast(
          `${__rootT('started_successfully', {
            resource: __rootTc('download'),
          })}`
        )
      } else {
        errorToast(`${__rootT('operation_fail')}`)
      }
    })
}

export function bulkApprovePatchApi(ids) {
  return api.patch(`/patch/bulkapproval`, { objectIds: ids }).then(() => {
    successToast(
      `${__rootT(
        ids.length > 1
          ? 'updated_successfully_multiple'
          : 'updated_successfully',
        {
          resource: __rootTc('patch', ids.length),
        }
      )}`
    )
  })
}

export function bulkDeclinePatchApi(ids, computerId) {
  return api
    .patch(`/patch/${computerId || ''}/bulkdecline`, { objectIds: ids })
    .then(() => {
      successToast(
        `${__rootT(
          ids.length > 1
            ? 'updated_successfully_multiple'
            : 'updated_successfully',
          {
            resource: __rootTc('patch', ids.length),
          }
        )}`
      )
    })
}

export function downloadAllPatchApi(qualDetails) {
  return api.post(`patchlist/filedetails/byqual`, {
    qualDetails,
  })
}
